import React from 'react';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import './TeamCard.scss';

interface Props {
    image?: any;
    name: string;
    role: string;
}

const TeamCard: React.FC<Props> = ({ name, role, image }) => {
    const className = ['TeamCard'].join(' ').replace(/\s{2,}/g, ' ');

    return (
        <div className={className}>
            <div className="TeamCard--image">{image}</div>
            <Title size="m" noMargin>
                {name}
            </Title>
            <Text size="m" noMargin>
                {role}
            </Text>
        </div>
    );
};

export default TeamCard;
