import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import ProvidersList from './ProvidersList';

ProvidersListComponent.defaultProps = {
    title: 'provider.list.title',
    subtitle: 'provider.list.subtitle',
    ctaLabel: 'show.all',
    ctaLink: '/providers/',
    count: 8,
    ctaVariant: 'primary',
};

export default function ProvidersListComponent({
    title,
    subtitle,
    ctaLabel,
    ctaLink,
    ctaVariant,
    count,
    showOnlyCoaches = false,
    noHeading = false,
}) {
    const { t } = useTranslation();

    return (
        <div className="ProvidersListComponent" id="providers">
            {/* Introduction */}
            {!noHeading && (
                <Section container="short">
                    <Title align="center">{t(title)}</Title>

                    <div className="g_1_4 g_center">
                        <Divider spacing="m" />
                    </div>

                    <div className="g_2_3 g_center">
                        <Text align="center">{t(subtitle)}</Text>
                    </div>
                </Section>
            )}

            {/* Cards */}
            <Section container="large" spacingTop="s">
                <ProvidersList
                    count={count ? count : null}
                    showOnlyCoaches={showOnlyCoaches}
                />
            </Section>

            {/* CTA */}
            <Section container="short" spacingTop="s">
                <div className="g_center">
                    <Link to={ctaLink}>
                        <Button
                            iconArrow
                            variant={ctaVariant}
                            size="l"
                            label={t(ctaLabel)}
                        />
                    </Link>
                </div>
            </Section>
        </div>
    );
}
